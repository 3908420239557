import { useAuth } from "./useAuth";

export const useActionBy = () => {
  const { isEmployee, employeeId, courieUser, customClaims } = useAuth();

  const actionBy: string | undefined = isEmployee
    ? employeeId
    : courieUser?.id || customClaims?.courieUserId || undefined;

  return {
    createdBy: actionBy,
    updatedBy: actionBy,
    completedBy: actionBy,
    resetBy: actionBy,
  };
};
