import React, { useEffect, useState } from "react";
import Textarea from "../Textarea/Textarea";
import Button from "../Button";
import { useUpdateInternalNoteMutation } from "@api/graphql/generated/generated-types";
import { useCourieStore } from "@src/common/lib/store";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useActionBy } from "@src/common/hooks/useActionBy";

export type InternalNotePanelProps = {
  key: number;
  shipmentId?: string;
  textareaValue?: string;
};

function InternalNote({
  key: i,
  shipmentId,
  textareaValue: shipmentInternalNote,
}: InternalNotePanelProps) {
  const [textareaValue, setTextareaValue] = useState<string | undefined>("");
  const [originalText, setOriginalText] = useState<string | undefined>("");
  const [editMode, setEditMode] = useState<boolean>(false);
  const { updatedBy } = useActionBy();

  // Truncate based on character count
  const truncateNote = (note: string | undefined) => {
    const charLimit = 1000; // Adjust this limit based on your layout
    if (note && note.length > charLimit) {
      return note.substring(0, charLimit) + "...";
    }
    return note;
  };

  const [updateInternalNoteMutation, { loading }] =
    useUpdateInternalNoteMutation();
  const { showToast } = useCourieStore();

  useEffect(() => {
    setTextareaValue(shipmentInternalNote);
    setOriginalText(shipmentInternalNote);
  }, [shipmentInternalNote]);

  const handleSave = () => {
    if (shipmentId && updatedBy) {
      updateInternalNoteMutation({
        variables: {
          shipmentId: shipmentId,
          internalNote: textareaValue || "",
          updatedBy: updatedBy,
        },
      })
        .then(() => {
          showToast({
            message: "Note updated successfully",
            type: "success",
          });
          setOriginalText(textareaValue);
        })
        .catch((err) => {
          showErrorToast(err, showToast);
        });
      setEditMode(false);
    }
  };

  const isModified = textareaValue !== originalText;

  const handleCancel = () => {
    setEditMode(false);
    setTextareaValue(originalText);
  };

  return (
    <div
      key={i}
      className="flex flex-col gap-2 rounded-md px-2 py-1 bg-slate-100 mb-2"
    >
      {!editMode ? (
        <>
          <p className="text-gray-700 text-xs whitespace-pre-wrap">
            {textareaValue ? truncateNote(textareaValue) : ""}
          </p>
          <button
            className="text-left text-blue-500 text-xs hover:underline hover:cursor-pointer"
            onClick={() => setEditMode(true)}
          >
            {textareaValue ? "Read More / Edit Note" : "+ Add Note"}
          </button>
        </>
      ) : (
        <div className="text-xs">
          <Textarea
            autoFocus={true}
            overrides={{
              Input: {
                style: {
                  minHeight: "150px",
                  maxHeight: "200px",
                  padding: "0 px",
                },
              },
            }}
            value={textareaValue}
            size="mini"
            onChange={(e) => setTextareaValue(e.target.value)}
            placeholder="Type your note here"
            clearOnEscape
          />
          <div className="flex justify-end mt-0.5 gap-2">
            <Button
              size={"xs"}
              outline={true}
              color={"secondary"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              isProcessing={loading}
              onClick={() => handleSave()}
              size={"xs"}
              disabled={!isModified}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default InternalNote;
